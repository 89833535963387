const CARD_STATUS = {
    INACTIVE: 0,
    ACTIVE: 1,
    PENDING: 2
}

const SUBSCRIPTION_STATUS = {
    UNSUBSCRIBED: 0,
    PAID: 1,
    PENDING_CANCELLATION: 2,
    PAID_OVERDUE_ACTIVE: 3,
    PAYMENT_OVERDUE_BLOCKED: 4,
    CANCELLED: 5,
}

export {
    CARD_STATUS,
    SUBSCRIPTION_STATUS
}