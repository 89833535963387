<template>
  <v-form ref="form" lazy-validation>
    <v-layout column>
      <v-layout
        v-if="subscription.status === null"
        align-center
        column
        class="mt-4"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          width="7"
          size="60"
          class="ma-3"
        ></v-progress-circular>
        <DefaultSubtitle class="ma-3">Wczytuję dane...</DefaultSubtitle>
      </v-layout>
      <template v-else-if="isSubscriptionActive">
        <v-layout align-center class="mx-4 mb-4">
          <DefaultSubtitle color="primary">Dane karty</DefaultSubtitle>
        </v-layout>
        <LabelTextField label="POSIADACZ KARTY" :value="card.holder" disabled>
        </LabelTextField>
        <LabelTextField label="NUMER KARTY" :value="card.number" disabled>
        </LabelTextField>
        <LabelTextField label="ADRES E-MAIL" :value="card.email" disabled>
        </LabelTextField>
        <DefaultButton
          class="mx-4 mt-0"
          primary
          @click.prevent="
            () => {
              beforeRequest();
              const c = win.open('', '_blank');
              sub
                .changeCard()
                .then((reply) => {
                  getCard();
                  c.location.href = reply.link;
                })
                .catch((e) => {
                  con.log('e', e);
                  reset();
                });
            }
          "
          :disabled="isSubscriptionCancelled"
          >{{ changeCardText }}</DefaultButton
        >
        <v-layout align-center class="mx-4 mt-4">
          <DefaultSubtitle color="primary">Status subskrypcji</DefaultSubtitle>
        </v-layout>
        <DefaultLabel class="mx-4" v-html="subscriptionStatus"> </DefaultLabel>
        <DefaultLabel class="mx-4" v-html="subscriptionDetails"> </DefaultLabel>
        <DefaultButton
          class="mx-4 mt-6"
          @click.prevent="cancelSubscription"
          :disabled="!isSubscriptionPaid"
          >ANULUJ SUBSKRYPCJĘ</DefaultButton
        >
      </template>
      <template v-else>
        <v-layout column class="mx-4 mb-4">
          <DefaultSubtitle>BooksyMed</DefaultSubtitle>
          <DefaultLabel>
            Dodaj kartę i zacznij korzystać z BooksyMed!
          </DefaultLabel>
          <DefaultSubtitle class="mt-4"
            >{{ subscription.net_amount }} zł / miesiąc</DefaultSubtitle
          >
          <DefaultButton
            class="mx-0 mt-4"
            primary
            @click.prevent="
              () => {
                beforeRequest();
                const c = win.open('', '_blank');
                sub
                  .addCard()
                  .then((reply) => {
                    getCard();
                    c.location.href = reply.link;
                  })
                  .catch((e) => {
                    con.log('e', e);
                    reset();
                  });
              }
            "
            :loading="loading || isPending"
            >DODAJ KARTĘ</DefaultButton
          >
          <DefaultButton
            v-if="isPending"
            class="mx-0 mt-4"
            @click.prevent="removeCard"
            >ANULUJ</DefaultButton
          >
        </v-layout>
      </template>
    </v-layout>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";
import SubscriptionsService from "@/services/subscriptions.service";
import { CARD_STATUS, SUBSCRIPTION_STATUS } from "@/constants/subscriptions";

export default {
  mixins: [ProxyCRUDMixin],
  data() {
    return {
      isCardAdded: null,
      card: {
        number: "",
        holder: "",
        email: "",
        status: CARD_STATUS.PENDING,
      },
      subscription: {
        status: null,
        net_amount: "1.00",
      },
      sub: SubscriptionsService,
      con: console,
      win: window,
    };
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    LabelTextField: () => import("@/components/LabelTextField"),
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
    InfoButton: () => import("@/components/buttons/InfoButton"),
  },
  computed: {
    isSubscriptionPaid() {
      return SUBSCRIPTION_STATUS.PAID === this.subscription.status;
    },
    isSubscriptionCancelled() {
      return (
        SUBSCRIPTION_STATUS.PENDING_CANCELLATION === this.subscription.status
      );
    },
    isSubscriptionActive() {
      return [
        SUBSCRIPTION_STATUS.PAID,
        SUBSCRIPTION_STATUS.PENDING_CANCELLATION,
        SUBSCRIPTION_STATUS.PAID_OVERDUE_ACTIVE,
        SUBSCRIPTION_STATUS.PAYMENT_OVERDUE_BLOCKED,
      ].includes(this.subscription.status);
    },
    isPending() {
      return this.card.status === CARD_STATUS.PENDING;
    },
    isActive() {
      return this.card.status === CARD_STATUS.ACTIVE;
    },
    changeCardText() {
      if (
        [
          SUBSCRIPTION_STATUS.PAID_OVERDUE_ACTIVE,
          SUBSCRIPTION_STATUS.PAYMENT_OVERDUE_BLOCKED,
        ].includes(this.subscription.status)
      ) {
        return "ZMIEŃ KARTĘ I ZAPŁAĆ";
      } else {
        return "ZMIEŃ KARTĘ";
      }
    },
    subscriptionStatus() {
      switch (this.subscription.status) {
        case SUBSCRIPTION_STATUS.PAID:
          return "Subskrypcja jest <b>aktywna</b>.";
        case SUBSCRIPTION_STATUS.PENDING_CANCELLATION:
          return "Subskrypcja jest <b>anulowana</b>.";
        case SUBSCRIPTION_STATUS.PAID_OVERDUE_ACTIVE:
          return "<b>Nie udało się opłacić subskrypcji.</b>";
        case SUBSCRIPTION_STATUS.PAYMENT_OVERDUE_BLOCKED:
          return "Subskrypcja jest <b>nieaktywna</b>.";
        default:
          return "";
      }
    },
    subscriptionDetails() {
      switch (this.subscription.status) {
        case SUBSCRIPTION_STATUS.PAID:
          return `Następna płatność <b>${this.subscription.next_billing_date}</b>`;
        case SUBSCRIPTION_STATUS.PENDING_CANCELLATION:
          return `Subskrypcja będzie aktywna do dnia <b>${this.subscription.next_billing_date}</b>.`;
        case SUBSCRIPTION_STATUS.PAID_OVERDUE_ACTIVE:
          return `Subskrypcja będzie aktywna do dnia <b>${this.subscription.next_billing_date}</b>.`;
        case SUBSCRIPTION_STATUS.PAYMENT_OVERDUE_BLOCKED:
          return `Subskrypcja wygasła dnia <b>${this.subscription.next_billing_date}</b>.`;
        default:
          return "";
      }
    },
  },
  methods: {
    ...mapActions("ui", ["updateSubscriptionStatus"]),
    addCard() {
      this.beforeRequest();
      return SubscriptionsService.addCard()
        .then((reply) => {
          let newTab = window.open();
          newTab.location.href = reply.link;
          this.isCardAdded = true;
          this.getCard();
          this.reset();
        })
        .catch((e) => {
          console.log("E", e);
          this.handleError("Coś poszło nie tak");
        });
    },
    removeCard() {
      this.beforeRequest();
      return SubscriptionsService.removeCard()
        .then(() => {
          this.isCardAdded = false;
          this.card.status = CARD_STATUS.INACTIVE;
          this.updateSubscriptionStatus(false);
          this.reset();
        })
        .catch((e) => {
          console.log("E", e);
          this.handleError("Coś poszło nie tak");
        });
    },
    updateCard() {
      return this.removeCard().then(this.addCard);
    },
    async getCard() {
      this.beforeRequest();
      return SubscriptionsService.getCard()
        .then((reply) => {
          this.card = reply;
          console.log("THIS CARD", this.card);
          if (this.isActive) {
            this.isCardAdded = true;
            this.getSubscriptionDetails();
          } else if (this.isPending) {
            this.isCardAdded = false;
            setTimeout(this.getCard, 10000);
          }
        })
        .catch(() => {
          this.isCardAdded = false;
          this.card.status = CARD_STATUS.INACTIVE;
        })
        .finally(() => {
          this.reset();
        });
    },
    async getSubscriptionDetails() {
      this.beforeRequest();
      return SubscriptionsService.getSubscriptionsDetails()
        .then((reply) => {
          this.subscription = reply;
          if (
            [
              SUBSCRIPTION_STATUS.UNSUBSCRIBED,
              SUBSCRIPTION_STATUS.CANCELLED,
              SUBSCRIPTION_STATUS.PAYMENT_OVERDUE_BLOCKED,
            ].includes(this.subscription.status)
          ) {
            this.updateSubscriptionStatus(false);
          } else {
            this.updateSubscriptionStatus(true);
          }
        })
        .catch((e) => {
          console.log("ERROR", e);
          this.handleError("Error", e);
        })
        .finally(() => {
          this.reset;
        });
    },
    async cancelSubscription() {
      this.beforeRequest();
      return SubscriptionsService.cancelSubscription()
        .then((reply) => {
          this.subscription = reply;
        })
        .catch((e) => {
          console.log("ERROR", e);
          this.handleError("Error", e);
        })
        .finally(() => {
          this.reset;
        });
    },
  },
  async created() {
    await this.getSubscriptionDetails();
    await this.getCard();
  },
};
</script>
